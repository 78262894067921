import React, { useRef, useState, useEffect } from "react";
import "./audio-message.scss"; // Custom CSS for styling
import { AudioVisualizer } from "react-audio-visualize";
import { getAudio } from "../../../../api/firebase-storage";

interface AudioPlayerProps {
    audioUrlPath: string;
    isMe: boolean;
    timestamp: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = (props: AudioPlayerProps) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState("0:00");
    const [duration, setDuration] = useState("0:00");
    const [blob, setBlob] = useState<Blob>();
    const visualizerRef = useRef<HTMLCanvasElement>(null)


    useEffect(() => {
       getAudio(props.audioUrlPath)
       .then((output) => {
            setBlob(output.blob);
            setAudioUrl(output.url);
       })
    }, [props.audioUrlPath]);
  
    // Format time in minutes and seconds
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const togglePlay = async () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            const duration = audioRef.current.duration;
            setProgress((currentTime / duration) * 100);
            setCurrentTime(formatTime(currentTime));
        }
    };

    const handleLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(formatTime(audioRef.current.duration));
        }
    };

    const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (audioRef.current) {
            const newTime = (parseFloat(event.target.value) / 100) * audioRef.current.duration;
            audioRef.current.currentTime = newTime;
            setProgress(parseFloat(event.target.value));
        }
    };

    return (
        <div
            className={
                props.isMe
                ? "single-message__container is-me fade-in"
                : "single-message__container is-not-me fade-in"
            }
        >
            <div className="audio-player">
                <div className="player-container">
                    <div className=".play-pause-btn:hover">
                        {isPlaying ?
                            <button className="pause-btn" onClick={togglePlay}>⏸</button>
                            :
                            <button className="play-btn" onClick={togglePlay}>▶</button>
                        } 
                    </div>

                    <div className="visualizer-container"> 
                        {blob && 
                            <AudioVisualizer
                                ref={visualizerRef}
                                blob={blob}
                                width={330}
                                height={75}
                                barWidth={2}
                                gap={1}
                                barColor={'#ffffff'}
                                barPlayedColor="#000000"
                            />
                        }
                        <div
                            className="progress-overlay"
                            style={{
                                width: `${progress}%`,
                                height: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            }}
                        ></div>
                    </div>
                    {/* <span className="time">{currentTime}</span> */}
                    {/* <span className="duration">{duration}</span> */}
                </div>
                { audioUrl && 
                    <audio
                        ref={audioRef}
                        src={audioUrl}
                        onTimeUpdate={handleTimeUpdate}
                        onLoadedMetadata={handleLoadedMetadata}
                        onEnded={() => setIsPlaying(false)}
                    />
                }
            </div>
            {props.isMe ? (
                <div className="single-message__info align-right">{currentTime } • {props.timestamp}</div>
            )
            : <div className="single-message__info align-left">{currentTime } </div>
            }
        </div>
    );
};

export default AudioPlayer;
