import React, { useState } from "react";
import "./chat-input.scss";
import SendIcon from "../../../../svg/send-icon";
import DeleteIcon from "../../../../svg/delete";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { AudioVisualizer } from "react-audio-visualize";

interface ChatInputProps {
  onTextSendClicked: (message: string) => void;
  onAudioSendClicked: (blob: Blob) => void;
}

const ChatInput = (props: ChatInputProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const [blob, setBlob] = useState<Blob>();
  const recorder = useAudioRecorder();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      sendMessageToServer();
    }
  };

  const sendMessageToServer = () => {
    if (blob) {
      console.log("Sending audio message to server");
      props.onAudioSendClicked(blob);
      handleDeleteAudio();
    } else {
      if (inputValue.trim() !== "") {
        props.onTextSendClicked(inputValue);
        setInputValue("");
        // Clear the input value after sending
      }
    }
  };

  const handleDeleteAudio = () => {
    setBlob(undefined);
  }

  return (
    <>
      <div className="chat-input__container">
        <div
          className="chat-input__container-mic"
          >
          <AudioRecorder
            onRecordingComplete={setBlob}
            recorderControls={recorder}
            showVisualizer={true}
            
          />
        </div>

        {blob && (
            <div
            className="chat-input__container-delete"
            onClick={handleDeleteAudio}
            >
            <DeleteIcon height="20" width="20" fill="black" />
          </div>
          )}

        { blob
        ? (
          <AudioVisualizer
            blob={blob}
            width={100}
            height={75}
            barWidth={1}
            gap={1}
            barColor={'#ffffff'}
          />
        )
        : (
          recorder.mediaRecorder
          ? (<div/>)
          : (
            <textarea
              placeholder="Type your message..."
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={inputValue}
              rows={3}
              cols={50}
              id="myTextarea"
            />
          )
          
        )}
 
        <div style={{marginLeft:"1rem"}}/>
        <div
          className="chat-input__container-icon"
          onClick={sendMessageToServer}
        >
          <SendIcon height="18" width="16" fill="black" />
        </div>
      </div>
      <div className="chat-input__disclaimer">
        Noah is under training. Inaccuracies in responses are possible.
      </div>
    </>
  );
};

export default ChatInput;
