import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "./firebase-config";
import axios from 'axios';


export async function getAudio(audioUrlPath: string): Promise<{ url: string, blob: Blob }> {
    console.log(`audio url path: ${audioUrlPath}`);
    const url = await getAudioMessageDownloadUrl(audioUrlPath);
    console.log(`audio url: ${url}`);
    const blob = await fetchAudioBlob(url);
    return { url, blob };
}
async function getAudioMessageDownloadUrl(audioUrlPath: string) {
    const storageRef = ref(storage, audioUrlPath);
    const downloadUrl = await getDownloadURL(storageRef);
    return downloadUrl
}

async function fetchAudioBlob(url: string): Promise<Blob> {
    try {
        const response = await axios.get(url, {
            responseType: "blob",
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching audio:", error);
        throw error;
    }
};