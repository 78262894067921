import { IconProps } from "../api/model/icon-props";

const Microphone = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            onClick={props.onClick}
            width={props.width ?? "19"}
            height={props.height ?? "19"}
            viewBox="0 0 24 24" id="mic"
            style={{ cursor: "pointer"}}
            fill={props.fill ?? "white"}>
            <g>
                <g>
                    <path d="M12 15a4 4 0 0 0 4-4V6a4 4 0 0 0-8 0v5a4 4 0 0 0 4 4z"></path>
                    <path d="M19 11a1 1 0 0 0-2 0 5 5 0 0 1-10 0 1 1 0 0 0-2 0 7 7 0 0 0 6 6.92V20H8.89a.89.89 0 0 0-.89.89v.22a.89.89 0 0 0 .89.89h6.22a.89.89 0 0 0 .89-.89v-.22a.89.89 0 0 0-.89-.89H13v-2.08A7 7 0 0 0 19 11z"></path>
                </g>
            </g>
        </svg>
    );
};

export default Microphone;
