import React from "react";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import LogOutIcon from "../../svg/logOutIcon";
import { logOutUser } from "../../api/firestore";
import Microphone from "../../svg/microphone";

interface HeaderProps {
  showLogOutButton: boolean;
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();

  const handleLogOutEvent = () => {
    logOutUser();
  };

  const handleMicrophoneEvent = () => {
    navigate('/voice');
  };

  return (
    <div className="header-container">
      <div className="header-logo" onClick={() => navigate('/chat')}>Noah</div>
      {props.showLogOutButton && (
        <div className="header-icons">
          
          <div className="header-icon">
            <Microphone
              height="1.25rem"
              width="1.25rem"
              fill="#fff"
              onClick={handleMicrophoneEvent}
            />
          </div>
          <div className="header-text"
            onClick={handleMicrophoneEvent}
          >Voice Mode</div>
          <div className="header-icon">
            <LogOutIcon
              height="1.25rem"
              width="1.25rem"
              fill="#fff"
              onClick={handleLogOutEvent}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
