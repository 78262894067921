import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../components/header/header";
import "./realtime-voice.scss";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { generateDocId, processAudioToText } from "../../api/firestore";
import { useNavigate } from "react-router-dom";

const SILENCE_THRESHOLD = 0.1; 
const SILENCE_DURATION = 3000; 

const RealtimeVoice = () => {
    const [blob, setBlob] = useState<Blob>();
    const [isProcessing, setIsProcessing] = useState(false);
    const [audioUrl, setAudioUrl] = useState<string>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [showRecordAgain, setShowRecordAgain] = useState(false);
    const [isCallConnected, setIsCallConnected] = useState(false);
    const sessionId = useMemo(() => generateDocId(), []);

    const audioRef = useRef<HTMLAudioElement>(null);
    const silenceStartRef = useRef<number | null>(null);

    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        mediaRecorder
    } = useAudioRecorder();

    // Add these refs for silence detection
    const audioContextRef = useRef<AudioContext | null>(null);
    const analyserRef = useRef<AnalyserNode | null>(null);
    const sourceRef = useRef<MediaStreamAudioSourceNode | null>(null);

    const navigate = useNavigate();

    // When recording finishes, process audio
    useEffect(() => {
        if (recordingBlob) {
            setBlob(recordingBlob);
            handleProcessAudio(recordingBlob);
        }
    }, [recordingBlob]);

    // Setup event listeners for audio playback
    useEffect(() => {
        const audio = audioRef.current;
        if (!audio || !audioUrl) return;

        const onLoadedMetadata = async () => {
            try {
                console.log("Playing audio");
                await audio.play();
            } catch (err) {
                console.warn("Autoplay may be blocked. User interaction required.", err);
            }
        };

        const onPlay = () => {
            console.log("Audio playing");
            setIsPlaying(true);
        };

        const onEnded = async () => {
            console.log("Audio ended");
            setIsPlaying(false);
            setShowRecordAgain(true);
            console.log('Audio ended, showRecordAgain set to true');
            setAudioUrl(undefined);

            // Automatically start recording again
            try {
                await startRecording();
                console.log("Recording started again after playback ended");
            } catch (error) {
                console.error("Failed to start recording again:", error);
                setIsListening(false);
            }
        };

        console.log("Adding event listeners to audio element");
        audio.addEventListener('loadedmetadata', onLoadedMetadata);
        audio.addEventListener('play', onPlay);
        audio.addEventListener('ended', onEnded);

        return () => {
            console.log("Removing event listeners from audio element");
            audio.removeEventListener('loadedmetadata', onLoadedMetadata);
            audio.removeEventListener('play', onPlay);
            audio.removeEventListener('ended', onEnded);
        };
    }, [audioUrl]);

    // Add effect to log state changes
    useEffect(() => {
        console.log('showRecordAgain changed:', showRecordAgain);
    }, [showRecordAgain]);

    // Add effect for silence detection setup
    useEffect(() => {
        if (mediaRecorder?.stream && isRecording) {
            setupSilenceDetection(mediaRecorder.stream);
        }
        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
                audioContextRef.current = null;
            }
            if (sourceRef.current) {
                sourceRef.current.disconnect();
                sourceRef.current = null;
            }
            if (analyserRef.current) {
                analyserRef.current.disconnect();
                analyserRef.current = null;
            }
            silenceStartRef.current = null;
        };
    }, [mediaRecorder?.stream, isRecording]);

    // Add silence detection setup function
    const setupSilenceDetection = (stream: MediaStream) => {
        audioContextRef.current = new AudioContext();
        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        
        analyserRef.current.fftSize = 256;
        sourceRef.current.connect(analyserRef.current);
        
        detectSilence();
    };

    // Add silence detection function
    const detectSilence = () => {
        if (!isRecording || !analyserRef.current) return;

        const analyser = analyserRef.current;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteTimeDomainData(dataArray);

        // Calculate RMS (Root Mean Square) value
        let sumSquares = 0;
        for (let i = 0; i < dataArray.length; i++) {
            const amplitude = (dataArray[i] - 128) / 128;
            sumSquares += amplitude * amplitude;
        }
        const rms = Math.sqrt(sumSquares / dataArray.length);

        const now = Date.now();
        
        // Debug log the current RMS value
        // console.log('Current RMS:', rms, 'Threshold:', SILENCE_THRESHOLD);

        // Check if the current audio level is below the silence threshold
        if (rms < SILENCE_THRESHOLD) {
            // If we haven't started counting silence, start now
            if (silenceStartRef.current === null) {
                // console.log('Silence started at:', now);
                silenceStartRef.current = now;
            } else {
                // Check how long we've been silent
                const silenceDuration = now - silenceStartRef.current;
                // console.log('Silence duration:', silenceDuration, 'ms');
                
                // Only stop if we've been silent long enough
                if (silenceDuration >= SILENCE_DURATION) {
                    // console.log('Silence duration reached, stopping recording');
                    stopRecording();
                    silenceStartRef.current = null;
                    setIsListening(false);
                    return; // Stop checking for silence
                }
            }
        } else {
            // If there's sound, reset the silence counter
            if (silenceStartRef.current !== null) {
                // console.log('Silence broken by RMS:', rms);
                silenceStartRef.current = null;
            }
        }

        // Continue checking for silence
        requestAnimationFrame(detectSilence);
    };

    const handleCancel = () => {
        setIsCallConnected(false);
        setIsListening(false);
        setIsPlaying(false);
        setIsProcessing(false);
        setShowRecordAgain(false);
        stopRecording();
        setBlob(undefined);
        setAudioUrl(undefined);
        silenceStartRef.current = null;
        
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        if (sourceRef.current) {
            sourceRef.current.disconnect();
            sourceRef.current = null;
        }
        if (analyserRef.current) {
            analyserRef.current.disconnect();
            analyserRef.current = null;
        }
        
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }

        navigate("/chat");
    };

    const handleProcessAudio = async (audioBlob: Blob) => {
        console.log("Processing audio in 201 line")
        if (!audioBlob) return;
        
        setIsProcessing(true);
        try {
            const url = await processAudioToText(audioBlob, sessionId);
            if (!url) {
                throw new Error("No URL returned from processing");
            }
            setAudioUrl(url);
        } catch (error) {
            console.error('Error processing audio:', error);
            setIsListening(true);
            startRecording();
        } finally {
            setIsProcessing(false);
        }
    };

    const startCall = async () => {
        setIsCallConnected(true);
        setBlob(undefined);
        setAudioUrl(undefined);
        setIsProcessing(false);
        setShowRecordAgain(false);
        
        setIsListening(true);
        try {
            await startRecording();
            console.log("Call started, recording begun");
        } catch (error) {
            console.error("Failed to start call:", error);
            setIsListening(false);
        }
    };

    const getStatusText = () => {
        if (isRecording) {
            return silenceStartRef.current 
                ? "Detecting silence..." 
                : "Recording...";
        }
        if (isProcessing) return "Processing audio...";
        if (isPlaying) return "Playing response...";
        return "Ready to record";
    };

    return (
        <div className="voice-container">
            <div className="voice-container__header">
                <Header showLogOutButton={true} />
            </div>
            <div className="voice-container__body">
                <div className="voice-container__status">
                    {getStatusText()}
                </div>
                <div className="voice-container__controls">
                    {!isCallConnected && (
                        <button 
                            className="control-button start"
                            onClick={startCall}
                        >
                            Start Call
                        </button>
                    )}
                    
                    {audioUrl && (
                        <audio 
                            ref={audioRef}
                            src={audioUrl}
                            autoPlay
                            className="audio-player"
                        />
                    )}
                    
                    {isCallConnected && (
                        <button 
                            className="control-button cancel"
                            onClick={handleCancel}
                        >
                            Cancel Call
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RealtimeVoice;
