// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseProdConfig from "./config/config-prod.json";
import * as firebaseDevConfig from "./config/config-dev.json";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

export const firebaseConfig = process.env.REACT_APP_ENV === "production"
? firebaseProdConfig
: firebaseDevConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app)
const functions = getFunctions(app);

function signOut() {
  auth.signOut();
}

function getUserId() {
  return auth.currentUser?.uid;
}

export { auth, firestore, storage, functions, signOut, getUserId };