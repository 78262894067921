import { IconProps } from "../api/model/icon-props";

const SendIcon = (props: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? "19"}
      height={props.height ?? "17"}
      viewBox="0 0 19 17"
      fill={props.fill ?? "white"}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.71623 0.357086L2.68679 0.343835C2.48084 0.251114 2.2755 0.158674 2.10229 0.0986753C1.94328 0.0435925 1.62312 -0.057049 1.26865 0.0415821C0.86807 0.153042 0.543163 0.446278 0.391346 0.833365C0.257003 1.1759 0.324396 1.50467 0.362939 1.66848C0.40492 1.84691 0.475891 2.06062 0.547077 2.27497L0.55725 2.30561L2.28318 7.50559L9.57489 8.41705L2.32111 9.32378L0.553419 14.6041L0.553413 14.6041C0.481443 14.8189 0.409751 15.033 0.367217 15.2117C0.328231 15.3754 0.259715 15.7048 0.39369 16.0482C0.544982 16.436 0.869919 16.73 1.27088 16.8419C1.62593 16.941 1.94682 16.84 2.10589 16.7849C2.27942 16.7248 2.48523 16.6321 2.69184 16.5391L17.495 9.8777C17.6963 9.78716 17.8982 9.69634 18.0557 9.60769C18.202 9.52535 18.4845 9.35467 18.6454 9.02915C18.8282 8.65947 18.8282 8.22571 18.6454 7.85603C18.4845 7.53051 18.202 7.35983 18.0557 7.2775C17.8982 7.18885 17.6963 7.09802 17.495 7.00747L2.71623 0.357086Z"
        fill={props.fill ?? "white"}
      />
    </svg>
  );
};

export default SendIcon;
