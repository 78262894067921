import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import PhoneAuth from "./pages/phone-auth/phone-auth";
import Chat from "./pages/chat/chat";
import RealtimeVoice from "./pages/realtime-voice/realtime-voice";
import { auth } from "./api/firebase-config";
import "react-phone-input-2/lib/style.css";

const App: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    listenToAuth();
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function listenToAuth() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });
  }

  function handleResize() {
    setIsMobile(window.innerWidth <= 768);
  }

  // Protected Route component
  const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    if (!userId) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  return (
    <BrowserRouter>
      <div className="app-container">
        <Routes>
          <Route path="/login" element={!userId ? <PhoneAuth /> : <Navigate to="/chat" replace />} />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat userId={userId!} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/voice"
            element={
              <ProtectedRoute>
                <RealtimeVoice />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/chat" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
